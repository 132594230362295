body {
  margin: 0;
}

.App {
  max-width: 500px;
}
.Wrapper {
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.02)
}

.App-link {
  color: #61dafb;
}

